import React, { memo } from "react"
import * as Styles from "../styles/Item.module.css"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import Container from "@mui/material/Container"
import Button from "@mui/material/Button"
import { createTheme, ThemeProvider } from "@mui/material/styles"

const theme = createTheme()

const Agreement = ({ open, handleClose, company }) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="md">
          <div className={Styles.box}>
            <h2 className={Styles.title}>利用規約</h2>
            <div className={Styles.contents}>
              <h3 className={Styles.sub_title}>
                第1条　本規約の範囲および変更
              </h3>
              <p className={Styles.text}>
                本規約は、{company.name}
                (以下「当社」という)が運営する当サイトのサービスを利用することに伴うすべての事項にわたり適用するものとします。
                当社は、利用者および株式会社スリーピース会員(以下「会員」という)の事前の了承を得ることなく本規約を変更できるものとし、会員はそれを承諾します。
                この変更は当社が提供する手段を通じて随時会員に発表するものとします。
              </p>
            </div>

            <div className={Styles.contents}>
              <h3 className={Styles.sub_title}>第2条　利用者</h3>
              <p className={Styles.text}>
                「利用者」とは、会員を含め、本サービスを閲覧、購入などを行った方をいいます。
                本サービスを利用した場合は利用者が本規約に合意したものとみなされます。
              </p>
            </div>
            <div className={Styles.contents}>
              <h3 className={Styles.sub_title}>第3条　会員</h3>
              <p className={Styles.text}>
                会員とは、本規約を承認の上、商品のご購入手続きをした方とします。
              </p>
            </div>
            <div className={Styles.contents}>
              <h3 className={Styles.sub_title}>第4条　個人情報の登録</h3>
              <ol className={Styles.list}>
                <li className={Styles.text}>
                  会員は、お客様情報に、利用者ご自身に関する真実かつ正確なデータを入力し、いかなる虚偽の申告も行わないものとします。
                </li>
                <li className={Styles.text}>
                  会員は、当社に申告した情報に変更が生じた場合、当社が別途指定する方法により、速やかに届け出るものとします。
                </li>
                <li className={Styles.text}>
                  会員登録時に、本サイトからのEメールによる送信に同意したものとします。
                </li>
                <li className={Styles.text}>
                  18歳未満の方がご利用にあたり個人情報を登録される場合には、保護者の同意を得ていただくものとします。
                </li>
              </ol>
            </div>
            <div className={Styles.contents}>
              <h3 className={Styles.sub_title}>第5条　個人情報の取扱い</h3>
              <p className={Styles.text}>
                個人情報は、当社「プライバシーポリシー」に基づいて取扱います。詳しくは「プライバシーポリシー」をご覧ください。
              </p>
            </div>
            <div className={Styles.contents}>
              <h3 className={Styles.sub_title}>第6条　メールアドレスの管理</h3>
              <ol className={Styles.list}>
                <li className={Styles.text}>
                  会員は、自分の管理に属する使用可能なメールアドレスを登録しなければならず、
                  当該メールアドレスが自己の管理に属さなくなったときには、
                  自己の管理に属する使用可能な別のメールアドレスに変更しなければならないとします。
                </li>
                <li className={Styles.text}>
                  登録したメールアドレス管理と、その使用に関しての責任は全て会員が負うものとし、当社は一切の責任を負わないものとします。
                </li>
                <li className={Styles.text}>
                  登録したメールアドレス会員本人のみが使用できるものとし、第三者の利用・譲渡・売買・貸与等の行為は禁止します。
                </li>
                <li className={Styles.text}>
                  当社は、登録したメールアドレス使用上の過失および第三者の利用に伴う損害の一切の責任を負わないものとします。
                </li>
              </ol>
            </div>
            <div className={Styles.contents}>
              <h3 className={Styles.sub_title}>
                第7条　会員資格の停止あるいは資格の抹消
              </h3>
              <p className={Styles.text}>
                本規約に違反した等の理由により、当社が不適当と判断した会員については、当社は会員への事前の承諾なしに、
                そのサービスの利用停止または会員資格の抹消を行うことができるもとのします。
              </p>
            </div>
            <div className={Styles.contents}>
              <h3 className={Styles.sub_title}>第8条　禁止事項</h3>
              <ol className={Styles.list}>
                <li className={Styles.text}>
                  利用者は本サービスを利用するにあたり以下の行為をおこなってはならないものとします。
                </li>
                <li className={Styles.text}>
                  法令に違反する行為、公序良俗に反する行為、またはそれらの恐れがある行為
                </li>
                <li className={Styles.text}>
                  当社が当サイトで提供するサービス、情報、および商品等に関する情報を、事前に当社の承諾を得ることなく、営利・非営利を問わず使用する行為
                </li>
                <li className={Styles.text}>
                  会員登録、商品購入の際に虚偽の内容を登録する行為
                </li>
                <li className={Styles.text}>
                  本サービスの運営を妨げる行為、その他本サービスに支障を来す恐れのある行為
                </li>
                <li className={Styles.text}>
                  クレジットカードを不正利用して本サービスを利用する行為
                </li>
                <li className={Styles.text}>
                  メールアドレスを不正に使用する行為
                </li>
                <li className={Styles.text}>
                  他の利用者、第三者もしくは当社の著作権、プライバシーその他の権利を侵害する行為、または迷惑、不利益、誹謗、中傷、もしくは損害等それらの恐れのある行為
                </li>
                <li className={Styles.text}>
                  その他当社が不適切と判断する行為
                </li>
                <li className={Styles.text}>転売行為</li>
              </ol>
            </div>
            <div className={Styles.contents}>
              <h3 className={Styles.sub_title}>第9条　退会</h3>
              <ol className={Styles.list}>
                <li className={Styles.text}>
                  退会された会員の個人情報は当社にて速やかに削除しますが、法令等の求めにより必要とされる情報は、消去できません。そのため、個人情報の一部は保持する事になりますが、お客様ご本人の申請がない限り利用できない状態とします。
                </li>
                <li className={Styles.text}>
                  会員が退会を希望する際は、{company.mail}
                  まで、その旨を届け出るものとします。
                </li>
                <li className={Styles.text}>
                  退会にあたって手数料等の支払は発生しません。
                </li>
                <li className={Styles.text}>
                  退会申請後、1週間以内に退会となります。
                </li>
              </ol>
            </div>
            <div className={Styles.contents}>
              <h3 className={Styles.sub_title}>第10条　サービスの停止・中止</h3>
              <ol className={Styles.list}>
                <li className={Styles.text}>
                  当社は以下に該当する場合、利用者への事前の通知、承諾なしに、サービス内容の一部または、全部をを停止または中止することがあります。
                  <p className="pl-3">
                    (1)コンピュータートラブル、ネットワークトラブル、メンテナンス等、弊社オンラインの運営管理上やむをえない場合。
                  </p>
                  <p className="pl-3">
                    (2)火災・停電が発生しサービスの提供ができないとき。
                  </p>
                  <p className="pl-3">
                    (3)天災地変によりサービスの提供ができないとき。
                  </p>
                  <p className="pl-3">(4)その他、当社が必要と判断した場合。</p>
                </li>
                <li className={Styles.text}>
                  当社は、提供するサービスの内容の全部または一部について、利用者の承認をことなく、変更、追加または削除を行うことができるものとします。
                </li>
                <li className={Styles.text}>
                  前第1項および第2項によりサービスが中止、変更等されたことにより利用者または他社が被った損害について、当社は一切責任を負わないものとします。
                </li>
              </ol>
            </div>
            <div className={Styles.contents}>
              <h3 className={Styles.sub_title}>第11条　合意管轄</h3>
              <p className={Styles.text}>
                当サイトのご利用に関して、利用者と当社の間で紛争が生じた場合、東京地方裁判所を利用者と当社の合意管轄裁判所とするものとします。
              </p>
            </div>
            <div className={Styles.contents}>
              <h3 className={Styles.sub_title}>第12条　準拠法</h3>
              <p className={Styles.text}>
                本規約の解釈、適応については日本法に準拠します。
              </p>
            </div>
            <p className={Styles.text}>施行：2012年5月28日</p>
            <DialogActions>
              <Button variant="outlined" size="small" onClick={handleClose}>
                閉じる
              </Button>
            </DialogActions>
          </div>
        </Container>
      </ThemeProvider>
    </Dialog>
  )
}

export default memo(Agreement)
