import React, { memo } from "react"
import * as Styles from "../styles/Item.module.css"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import Container from "@mui/material/Container"
import Button from "@mui/material/Button"
import { createTheme, ThemeProvider } from "@mui/material/styles"

const theme = createTheme()

const Delivery = ({ open, handleClose, company, deliveryRef = 'all' }) => {

  return (
    <Dialog open={open} onClose={handleClose}>
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="md">
          <div className={Styles.box}>
            { deliveryRef === 'all' && (
            <>
              <h2 className={Styles.title}>ご利用方法</h2>
              <p className={Styles.contents_title}>お支払い方法</p>

              <div className={Styles.tbl}>
                <div className={Styles.cell_left}>クレジット</div>
                <div className={Styles.cell_right}>
                  <p className={Styles.text}>
                    購入代金をクレジットカード会社より後日請求させていただきます。
                  </p>
                  <p className={Styles.text}>
                    お支払日は、お客様がご指定の各クレジットカード会社の規約に基づいた指定日のお支払いとなります。
                  </p>
                </div>
                <div className={Styles.cell_left}>代金引換</div>
                <div className={Styles.cell_right}>
                  <p className={Styles.text}>
                    全対象：代引き手数料
                    {company.charge > 0 ? (
                      <span>{company.commission}円（税込）</span>
                    ) : (
                      <span>無料</span>
                    )}
                  </p>
                  <p className={Styles.text}>
                    購入代金(商品代金+送料)をそのまま現金でお支払いください。
                  </p>
                </div>
                {company.amazon_flg > 0 ? (
                  <>
                    <div className={Styles.cell_left}>アマゾン・ペイ</div>
                    <div className={Styles.cell_right}>
                      <p className={Styles.text}>3～10営業日以内に発送</p>
                      <p className={Styles.text}>
                        購入代金をクレジットカード会社より後日請求させていただきます。
                      </p>
                      <p className={Styles.text}>
                        お支払日は、お客様がご指定の各クレジットカード会社の規約に基づいた指定日のお支払いとなります。
                      </p>
                    </div>
                  </>
                ) : (
                  ""
                )}

                <div className={Styles.cell_left}>後払い（コンビニ・郵便局・銀行・LINE Pay）</div>
                <div className={Styles.cell_right}>
                  <p className={Styles.text}>
                    ○請求書は商品に同封されていますので、ご確認ください。
                  </p>
                  <p className={Styles.text}>
                    注文者様のご住所とお届け先のご住所が異なる場合は、
                    請求書は商品に同封されず、購入者様へお送りいたします。
                    商品代金のお支払いは「コンビニ」「郵便局」「銀行」「LINE
                    Pay」どこでも可能です。
                    請求書の記載事項に従って発行日から14日以内にお支払いください
                  </p>
                  <p className={Styles.text}>
                    ○ご注意：後払い手数料：{company.np}円 （税込）
                  </p>
                  <p className={Styles.text}>
                    後払いのご注文には、株式会社ネットプロテクションズの後払いサービスが適用され、
                    同社へ代金債権を譲渡します。
                    <a
                      href="https://np-atobarai.jp/terms/atobarai-buyer.html"
                      target="_blank"
                      rel="noreferrer"
                      className="underline"
                    >
                      NP後払い利用規約及び同社のプライバシーポリシー
                    </a>
                    に同意して、 後払いサービスをご選択ください。
                    ご利用限度額は累計残高で55,000円（税込）迄です。
                    詳細はバナーをクリックしてご確認下さい。
                    ご利用者が未成年の場合、法定代理人の利用同意を得てご利用ください。
                  </p>
                  <p className="mb-1">
                    <a
                      href="http://www.np-atobarai.jp/about/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src="https://shopping.body-custom.net/img/wiz_newbanner468x64.png"
                        alt="NP後払い"
                        className="max-w-full"
                      />
                    </a>
                  </p>
                </div>
              </div>
            </>
            )}

            { (deliveryRef === 'all' || deliveryRef === 'delivery') && (
            <>
              <p className={Styles.contents_title}>配送方法・送料</p>
              <div className={Styles.tbl}>
                <div className={Styles.cell_left}>配送業者</div>
                <div className={Styles.cell_right}>
                  <p className={Styles.text}>ヤマト運輸にて発送致します。<br />
                  (配送業者のご指定はできかねます。予めご了承ください)</p>
                </div>
                <div className={Styles.cell_left}>送料</div>
                <div className={Styles.cell_right}>
                <p className={Styles.text}>1回のご注文商品合計金額(税込)が8,000円以上は送料無料、8,000円未満の場合は750円(税込)/沖縄・離島は1800円(税込)が別途必要となります。</p>
                </div>
                <div className={Styles.cell_left}>商品配送</div>
                <div className={Styles.cell_right}>
                  <p className={Styles.text}>
                  ご注文確認後、2営業日以内に商品を発送いたします。<br />
                  ご自宅への配送をご希望されないお客様は、最寄のヤマト運輸・営業所止めもご利用いただけます。<br />
                  最寄りの営業所が不明な場合は、住所の欄にご自宅の住所を入力し備考欄に【最寄りの営業所止め】とご入力下さい。
                  </p>
                </div>
              </div>
            </>
            )}

            { (deliveryRef === 'all' || deliveryRef === 'refund') && (
            <>
              <p className={Styles.contents_title}>返品・返金について</p>
              <div className={Styles.tbl}>
                <div className={Styles.cell_left}>不良品</div>
                <div className={Styles.cell_right}>
                <p className={Styles.text}>
                  商品には万全を期しておりますが、お届けした商品が破損・汚損している場合には商品到着後8日以内に当店までご連絡ください。責任を持ってお取替えを承らせていただきます。<br />
                  ※返品にかかる送料は当店負担となります。着払いにてお送りください。<br /><br />

                  【お客様サポート】<br />
                  03-3825-0343<br />
                  受付時間：10:00-17:00(土日祝日除く)
                </p>
                </div>
                <div className={Styles.cell_left}>返品</div>
                <div className={Styles.cell_right}>
                <p className={Styles.text}>お客様都合による返品は承っておりません。予めご了承ください。</p>
                </div>
              </div>
            </>
            )}

            <DialogActions>
              <Button variant="outlined" size="small" onClick={handleClose}>
                閉じる
              </Button>
            </DialogActions>
          </div>
        </Container>
      </ThemeProvider>
    </Dialog>
  )
}

export default memo(Delivery)
