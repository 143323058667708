import React, { memo } from "react"
import Dialog from "@mui/material/Dialog"
import Container from "@mui/material/Container"
import Button from "@mui/material/Button"
import { createTheme, ThemeProvider } from "@mui/material/styles"

const theme = createTheme()

const Law = ({ open, handleClose, company }) => {

  return (
    <Dialog open={open} onClose={handleClose}>
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="md">
          <div className="grid grid-cols-1 gap-0 mx-auto mb-5 p-2 max-w-screen-md">
            <div className="mb-5">
              <h2 className="text-3xl text-black-300 leading-9 font-extrabold tracking-tight border-b-2 mb-3 pb-3">
                特定商取引法に基づく表記
              </h2>
              <div className="grid grid-cols-4">
                <div className="col-span-4 sm:col-span-1 text-base font-bold bg-gray-200 align-top px-4 py-2">
                  販売業者
                </div>
                <div className="col-span-4 sm:col-span-3 border-t border-l border-r px-4 py-2">
                  {company.name}
                </div>
                <div className="col-span-4 sm:col-span-1 text-base font-bold bg-gray-200 align-top px-4 py-2">
                  運営統括責任者名
                </div>
                <div className="col-span-4 sm:col-span-3 border-t border-l border-r px-4 py-2">
                  {company.president}
                </div>
                <div className="col-span-4 sm:col-span-1 text-base font-bold bg-gray-200 align-top px-4 py-2">
                  郵便番号
                </div>
                <div className="col-span-4 sm:col-span-3 border-t border-l border-r px-4 py-2">
                  {company.zip_code}
                </div>
                <div className="col-span-4 sm:col-span-1 text-base font-bold bg-gray-200 align-top px-4 py-2">
                  住所
                </div>
                <div className="col-span-4 sm:col-span-3 border-t border-l border-r px-4 py-2">
                  {company.address}
                </div>
                <div className="col-span-4 sm:col-span-1 text-base font-bold bg-gray-200 align-top px-4 py-2">
                  商品代金以外の料金の説明
                </div>
                <div className="col-span-4 sm:col-span-3 border-t border-l border-r px-4 py-2">
                  <p>
                    代引き手数料：1万円未満・330円、3万円未満・440円、10万円未満・660円、10万円以上30万円まで・1,100円
                  </p>
                  <p>
                    送料：1回のご注文につき送料750円（税込）/沖縄・離島は1,800円（税込）
                  </p>
                </div>
                <div className="col-span-4 sm:col-span-1 text-base font-bold bg-gray-200 align-top px-4 py-2">
                  返品について
                </div>
                <div className="col-span-4 sm:col-span-3 border-t border-l border-r px-4 py-2">
                  <p>商品には万全を期しておりますが、お届けした商品が破損・汚損している場合には商品到着後8日以内に当店までご連絡ください。責任を持ってお取替えを承らせていただきます。</p>
                  <p>※返品にかかる送料は当店負担。着払いにてお送りください。</p>
                  <p>お客様都合による返品は承っておりません。予めご了承ください。 </p>
                </div>
                <div className="col-span-4 sm:col-span-1 text-base font-bold bg-gray-200 align-top px-4 py-2">
                  販売価格
                </div>
                <div className="col-span-4 sm:col-span-3 border-t border-l border-r px-4 py-2">
                  各商品ページにてご確認ください。
                </div>
                <div className="col-span-4 sm:col-span-1 text-base font-bold bg-gray-200 align-top px-4 py-2">
                  販売数量
                </div>
                <div className="col-span-4 sm:col-span-3 border-t border-l border-r px-4 py-2">
                  各商品ページにてご確認ください。
                </div>
                <div className="col-span-4 sm:col-span-1 text-base font-bold bg-gray-200 align-top px-4 py-2">
                  お支払い方法
                </div>
                <div className="col-span-4 sm:col-span-3 border-t border-l border-r px-4 py-2">
                  {company.payment}
                </div>
                <div className="col-span-4 sm:col-span-1 text-base font-bold bg-gray-200 align-top px-4 py-2">
                  商品引渡し時期
                </div>
                <div className="col-span-4 sm:col-span-3 border-t border-l border-r px-4 py-2">
                  <p>配送希望日がない場合、ご注文確認後2営業日以内に商品を発送いたします。</p>
                  <p>(お届け先情報に不備があった場合、正しい情報を確認でき次第の発送となります)</p>
                </div>
                <div className="col-span-4 sm:col-span-1 text-base font-bold bg-gray-200 align-top px-4 py-2">
                  支払時期
                </div>
                <div className="col-span-4 sm:col-span-3 border-t border-l border-r px-4 py-2">
                  <p>クレジットカード決済：商品注文時にお支払いが確定します。</p>
                  <p>代金引換：商品到着時、配達員の方へお支払いください。</p>
                  <p>NP後払い：商品到着後14日以内にお支払いください。</p>
                </div>
                <div className="col-span-4 sm:col-span-1 text-base font-bold bg-gray-200 align-top px-4 py-2">
                  電話番号
                </div>
                <div className="col-span-4 sm:col-span-3 border-t border-l border-r px-4 py-2">
                  {company.phone}
                </div>
                <div className="col-span-4 sm:col-span-1 text-base font-bold bg-gray-200 align-top px-4 py-2">
                  公開メールアドレス
                </div>
                <div className="col-span-4 sm:col-span-3 border-t border-l border-r px-4 py-2">
                  {company.mail}
                </div>
                <div className="col-span-4 sm:col-span-1 text-base font-bold bg-gray-200 align-top px-4 py-2">
                  ホームページアドレス
                </div>
                <div className="col-span-4 sm:col-span-3 border-t border-l border-r px-4 py-2">
                  {company.url}
                </div>
                <div className="col-span-4 sm:col-span-1 text-base font-bold bg-gray-200 align-top px-4 py-2">
                  サポート対応時間
                </div>
                <div className="col-span-4 sm:col-span-3 border-t border-l border-r border-b px-4 py-2">
                  受付時間{company.open_time}（{company.open_date}）
                </div>
              </div>
            </div>
            <div className="text-right">
              <Button variant="outlined" size="small" onClick={handleClose}>
                閉じる
              </Button>
            </div>
          </div>
        </Container>
      </ThemeProvider>
    </Dialog>
  )
}

export default memo(Law)
